import {inject} from '@angular/core';
import {CanMatchFn, Route, Router, UrlSegment} from '@angular/router';
import {Site} from '@app/models';
import {SiteConfigService} from '@app/services';

// cruiselines page for ACS and CI
export const cruiseLinesGuard: CanMatchFn = (
  route: Route,
  segments: UrlSegment[]
) => {
  const siteService = inject(SiteConfigService);
  const router = inject(Router);
  const site = siteService.getConfig().siteID;
  if (site === Site.Alaska || site === Site.CI) {
    return true;
  }

  // 404
  return router.parseUrl('/page-not-found');
};

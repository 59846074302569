<div id="form-wrapper" class="theme-{{site.siteID}} theme-Oceania:bg-gold-grad-1 theme-Silversea:bg-silver-grad-1 theme-Regent:bg-silver-grad-1 theme-Azamara:bg-silver-grad-1 p-1.5 flex flex-col rounded-sm">
  <div class="overflow-auto flex-1 bg-primary-100 bg-no-repeat w-full bg-bottom theme-Alaska:bg-cover" [style]="{ 'backgroundImage': 'url(' + (site.eSignupModal.backgroundImage | imgix:'fit=crop':'width=397') + ')'}">
    <div class="relative overflow-hidden">
      <div class="relative flex justify-end">
        <button (click)="closeModal($event)" class="mr-5 my-5 h-8 w-8 text-3xl border rounded-sm border-gray-100" aria-label="Close modal">
          <icon-close [width]="15" class="flex justify-center items-center text-gray-100"></icon-close>
        </button>
      </div>
      <div class="relative flex justify-center items-center my-6 px-6">
        <h2 class="opt-out uppercase text-3xl font-extrabold text-white">
          <span class="font-theme-display font-normal">enjoy</span>
          insider 
          <span class="font-theme-display font-normal">deals</span>
        </h2>
      </div>
      <img height="60" src="{{ site.eSignupModal.divider | imgix:'fit=crop':'width=60':'height=60'}}" alt="" class="my-7 mx-auto">
      <div class="relative px-10 text-white flex flex-col items-center">
        <p class="font-normal uppercase tracking-widest text-tertiary-200">Get Exclusive Access</p>
        <p class="text-sm">Subscribe to our weekly deals today to stay up to date on all of our deals.</p>
      </div>
    </div>
    <div class="form-cont flex-1 flex items-center px-10 py-8">
      <div class="w-full h-40">
        <div [id]="hsTargetId" class="w-full"></div>
        <button 
        #formDismiss
        (click)="closeModal($event)"
        class="block mx-auto bg-primary-300 border-2 border-rounded border-tertiary-100 rounded px-4 py-2 text-sm font-bold text-tertiary-100 w-44 uppercase">No Thanks</button>
      </div>
    </div>
  </div>
</div>
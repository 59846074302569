import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {Hero, Site} from '@app/models';
import {ImgixPipe} from '@app/pipes';

@Component({
  selector: 'app-hero',
  imports: [ImgixPipe],
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.css'],
})
export class HeroComponent {
  @Input() siteID!: Site;
  @Input() showVid = false;

  @Input() hero: Hero | null = null;

  constructor(private router: Router) {}

  handleClick(url: string) {
    // eslint-disable-next-line
    const apiUrl = new URL(url);
    const routerUrl = apiUrl.pathname + apiUrl.search;
    this.router.navigateByUrl(routerUrl);
  }
}

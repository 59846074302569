import {inject} from '@angular/core';
import {CanMatchFn, Route, Router, UrlSegment} from '@angular/router';
import {Site} from '@app/models';
import {SiteConfigService} from '@app/services';

// disallow ACS
export const destinationsGuard: CanMatchFn = (
  route: Route,
  segments: UrlSegment[]
) => {
  const siteService = inject(SiteConfigService);
  const router = inject(Router);

  if (siteService.getConfig().siteID === Site.Alaska) {
    // 404
    return router.parseUrl('/page-not-found');
  }

  return true;
};

import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import {Params, Router} from '@angular/router';
import {BehaviorSubject, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {
  ESignupDialogService,
  MeiliSearchService,
  OverlayService,
  SearchService,
  SiteConfigService,
} from '@app/services';
import {Facet, FacetType, FacetConfig, FacetField, Site} from '@app/models';
import {FilterIcon, PlusIcon, SearchIcon, Ship2Icon} from '@app/icons';
import {FacetDateAccordionComponent} from '@app/facets-new/facet-date/facet-date-accordion/facet-date-accordion.component';
import {FacetStandardAccordionComponent} from '@app/facets-new/facet-standard/facet-standard-accordion/facet-standard-accordion.component';
import {FacetDateWideComponent} from '@app/facets-new/facet-date/facet-date-wide/facet-date-wide.component';
import {FacetStandardWideComponent} from '@app/facets-new/facet-standard/facet-standard-wide/facet-standard-wide.component';
import {SearchBarService2} from '@app/services/search-bar-service-2.service';
import {SearchDriver, defaultRequestState} from '@app/services/search-driver';
import {facetBucketMap} from '@app/search-configs/facet-bucket-map';
import {FacetRangeWideComponent} from '../facets-new/facet-range/facet-range-wide/facet-range-wide.component';
import {FacetRangeAccordionComponent} from '@app/facets-new/facet-range/facet-range-accordion/facet-range-accordion.component';
import {SEARCH_CONFIGS} from '@app/search-configs/search.config';

@Component({
  selector: 'search-bar-2',
  imports: [
    SearchIcon,
    FilterIcon,
    Ship2Icon,
    PlusIcon,
    FacetDateAccordionComponent,
    FacetStandardAccordionComponent,
    FacetDateWideComponent,
    FacetStandardWideComponent,
    FacetRangeWideComponent,
    FacetRangeAccordionComponent,
  ],
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css'],
})
export class SearchBar2Component implements OnInit, OnDestroy {
  readonly Site = Site;
  readonly FacetType = FacetType;
  siteID;
  lengthBuckets = facetBucketMap['duration'];
  priceBuckets = facetBucketMap['min_price'];
  searchDriver: SearchDriver;
  searchQueryParams: Params = {};
  facetConfig: FacetConfig;
  facetDataArr: Facet[] = [];
  fetchFacetOptions$ = new Subject<FacetField>();

  showMore = false;

  @Input() closeAll$ = new BehaviorSubject<boolean>(false);
  activeFacet: FacetField | null = null;

  @Output() didSearch = new EventEmitter<void>();

  private destroy$ = new Subject<void>();

  constructor(
    private siteConfigService: SiteConfigService,
    private searchBarService: SearchBarService2,
    private router: Router,
    private overlayService: OverlayService,
    private searchService: SearchService,
    private meiliSearchService: MeiliSearchService,
    private eSignupDialogService: ESignupDialogService
  ) {
    this.siteID = this.siteConfigService.getConfig().siteID;

    this.searchDriver = this.searchBarService.searchDriver;
    this.facetConfig = this.searchBarService.searchDriver.config;

    // facet states
    this.facetDataArr = this.meiliSearchService.setupFacets(
      this.facetConfig.facets,
      this.fetchFacetOptions$,
      this.searchDriver
    );

    // sub to overlay click
    this.overlayService.didClick
      .pipe(
        takeUntil(this.destroy$),
        filter(didClick => didClick)
      )
      .subscribe(() => {
        this.closeMenu();
      });
  }

  ngOnInit(): void {
    this.closeAll$
      .pipe(
        takeUntil(this.destroy$),
        filter(closeAll => closeAll)
      )
      .subscribe(() => {
        this.closeMenu();
      });
  }

  closeMenu() {
    this.activeFacet = null;
    this.overlayService.hide();
    this.eSignupDialogService.setSearchActive(false);
  }

  toggleOption(facetOption: {
    FacetName: string;
    FacetValue: string;
    Selected: boolean;
  }) {
    const facet = {
      FacetName: facetOption.FacetName as FacetField,
      FacetValue: facetOption.FacetValue,
    };
    if (facetOption.Selected) {
      this.searchDriver.removeFilter(facet);
    } else {
      this.searchDriver.addFilter(facet);
    }
  }

  updateRangeOption(facetOption: {FacetName: string; FacetValue: string}) {
    const facet = {
      FacetName: facetOption.FacetName as FacetField,
      FacetValue: facetOption.FacetValue,
    };
    this.searchDriver.clearFilter(facet.FacetName);
    this.searchDriver.addFilter(facet);
  }

  clearFacets(fields: FacetField[]) {
    fields.forEach(field => this.searchDriver.clearFilter(field));
  }

  doSearch() {
    this.didSearch.emit();
    this.activeFacet = null;
    this.overlayService.hide();

    // get facets$
    if (this.router.url.startsWith('/search')) {
      this.searchBarService.searchDriver.doSyncFilters$.next(true);
    } else {
      const filters = this.searchDriver.facets$.getValue();
      const requestState = {
        ...defaultRequestState,
        ...SEARCH_CONFIGS[this.siteID].initRequestState,
        filters,
      };
      const stateParams = this.searchService.transformRequestStateToQueryParams(
        requestState,
        this.searchDriver.config.facets.flatMap(facet => {
          switch (facet.type) {
            case FacetType.destination:
            case FacetType.duration:
            case FacetType.price:
              return facet.fields;
            default:
              return facet.field;
          }
        })
      );

      this.router.navigate(['search'], {
        queryParams: stateParams,
      });
    }
  }

  shouldShowMore() {
    this.showMore = true;
  }

  toggleWideFacet(field: FacetField) {
    if (this.activeFacet === field) {
      this.closeMenu();
    } else {
      // check should toggle showMore
      const matchIdx = this.facetConfig.facets.findIndex(facet => {
        switch (facet.type) {
          case FacetType.destination:
          case FacetType.duration:
          case FacetType.price:
            return facet.fields.some(
              facetConfigField => facetConfigField === field
            );
          default:
            return facet.field === field;
        }
      });
      if (matchIdx > -1) {
        if (matchIdx > 1) {
          this.showMore = true;
        }
        this.activeFacet = field;
        this.overlayService.show();
        this.fetchFacetOptions$.next(field);
      }
      this.eSignupDialogService.setSearchActive(true);
    }
  }

  toggleAccordionFacet(field: FacetField) {
    if (this.activeFacet === field) {
      this.activeFacet = null;
    } else {
      this.activeFacet = field;
      this.fetchFacetOptions$.next(field);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}

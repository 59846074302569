import {Component, OnDestroy, OnInit} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {SiteConfigService, NavigationService} from '@app/services';
import {SearchBarService2} from './services/search-bar-service-2.service';
import {SearchDriver} from './services/search-driver';
import {SEARCH_CONFIGS} from './search-configs/search.config';

@Component({
  selector: 'app-root',
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private navigationService: NavigationService,
    private searchBarService: SearchBarService2,
    private siteConfigService: SiteConfigService
  ) {}

  ngOnInit(): void {
    // setup navigationservice to start tracking nav changes
    this.navigationService.init();

    // setup site-wide searchDriver
    const config = {
      ...SEARCH_CONFIGS[this.siteConfigService.getConfig().siteID].facetConfig,
    };
    this.searchBarService.searchDriver = new SearchDriver(config);
  }

  ngOnDestroy(): void {
    this.searchBarService.searchDriver.destroy();
  }
}

<dialog #dialog id="esignup" (click)="outsideClick($event)" class="overflow-hidden">
  <div #dialogChild>
    @switch (site.siteID) {
      @case (SITES.Windstar) {
        <app-wcs-e-signup></app-wcs-e-signup>
      }
      @default {
        <app-e-signup></app-e-signup>
      }
    }
  </div>
</dialog>
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
  AfterViewInit,
  Renderer2,
} from '@angular/core';
import {Microsite, Site} from '@app/models';
import {SiteConfigService, ESignupDialogService} from '@app/services';
import {Subject, takeUntil} from 'rxjs';
import {WCSESignupComponent} from './site-dialogs/wcs/wcs-e-signup.component';
import {ESignupComponent} from './site-dialogs/e-signup.component';

@Component({
  selector: 'app-e-signup-dialog',
  imports: [WCSESignupComponent, ESignupComponent],
  styleUrl: './e-signup-dialog.component.css',
  templateUrl: './e-signup-dialog.component.html',
})
export class ESignupDialogComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  readonly SITES = Site;
  site: Microsite;

  @ViewChild('dialog') dialog!: ElementRef<HTMLDialogElement>;
  @ViewChild('dialogChild') dialogChild!: ElementRef;

  escapeListener!: () => void;

  private destroy$ = new Subject<void>();

  constructor(
    private siteConfigService: SiteConfigService,
    private eSignupDialogService: ESignupDialogService,
    private renderer: Renderer2
  ) {
    this.site = this.siteConfigService.getConfig();
  }

  ngOnInit(): void {
    this.eSignupDialogService
      .getState()
      .pipe(takeUntil(this.destroy$))
      .subscribe(visible => {
        if (visible) {
          this.dialog.nativeElement.showModal();
        } else {
          this.dialog.nativeElement.close();
        }
      });
    // start timer to show modal after delay
    this.eSignupDialogService.initShowTimer();
  }

  ngAfterViewInit(): void {
    // update eSignupDialogService state on escape key press so WCS video will pause and mute
    this.escapeListener = this.renderer.listen(
      this.dialog.nativeElement,
      'close',
      this.handleEscape.bind(this)
    );
  }

  handleEscape() {
    this.eSignupDialogService.closeModal(false);
  }

  outsideClick(event: Event) {
    if (!this.dialogChild.nativeElement.contains(event.target)) {
      this.eSignupDialogService.closeModal(false);
    }
  }

  ngOnDestroy(): void {
    this.escapeListener();
    this.destroy$.next();
  }
}

<div class="flex flex-col justify-between min-h-screen">
  <header [class]="{'lg:relative': router.url === '/'}" class="sticky top-0 z-50">
    <app-header></app-header>
  </header>
  <div class="flex-grow">
    <router-outlet class="contents"></router-outlet>
  </div>
  <footer></footer>
  <!-- overlay -->
  <div (click)="overlayClick()" [class]="overlayService.active ? 'block' : 'hidden'" class="z-20 fixed inset-0 bg-black opacity-50"></div>
</div>
<div *appShellNoRender>
  <app-e-signup-dialog></app-e-signup-dialog>
</div>
<div class="relative flex justify-center">
  <div [class]="!showMore ? 'lg:max-w-[1070px]' : 'lg:max-w-none'" class="w-[300px] lg:w-full lg:flex lg:justify-between">
    @if (!showMore) {
      <div class="hidden lg:flex justify-center items-center lg:mr-12">
        <div class="mr-4">
          <icon-ship-2 fill="white"></icon-ship-2>
        </div>
        <div class="whitespace-nowrap text-white text-xl font-semibold">Cruise Search</div>
      </div>
    }
    <ul class="grid-container opt-out grow">
      @for (facetData of facetDataArr; track i; let i = $index) {
        <li [class]="showMore || i < 2 ? 'block' : 'hidden'" class="max-w-[300px] lg:max-w-none">
          <div class="lg:hidden">
            @switch (facetData.type) {
              @case (FacetType.date) {
                <facet-date-accordion [activeFacet]="activeFacet" [field]="facetData.field" [defaultLabel]="facetData.label" [icon]="facetData.icon" [options$]="facetData.options$" [selected$]="facetData.selected$" (toggleFacet)="toggleAccordionFacet($event)" (toggleOption)="toggleOption($event)" (clearFacet)="clearFacets([facetData.field])"></facet-date-accordion>
              }
              @case (FacetType.duration) {
                <facet-range-accordion [activeFacet]="activeFacet" [fields]="facetData.fields" [defaultLabel]="facetData.label" [icon]="facetData.icon" [facetType]="FacetType.duration" [options$]="facetData.options$" [selected$]="facetData.selected$" (toggleFacet)="toggleAccordionFacet($event)" (updateRange)="updateRangeOption($event)" (clearFacet)="clearFacets($event)"></facet-range-accordion>
              }
              @case (FacetType.price) {
                <facet-range-accordion [activeFacet]="activeFacet" [fields]="facetData.fields" [defaultLabel]="facetData.label" [icon]="facetData.icon" [facetType]="FacetType.price" [options$]="facetData.options$" [selected$]="facetData.selected$" (toggleFacet)="toggleAccordionFacet($event)" (updateRange)="updateRangeOption($event)" (clearFacet)="clearFacets($event)"></facet-range-accordion>
              }
              @case (FacetType.destination) {
                <facet-standard-accordion [activeFacet]="activeFacet" [fields]="facetData.fields" [defaultLabel]="facetData.label" [search]="facetData.search" [icon]="facetData.icon" [options$]="facetData.options$" [selected$]="facetData.selected$" (toggleFacet)="toggleAccordionFacet($event)" (toggleOption)="toggleOption($event)" (clearFacet)="clearFacets(facetData.fields)"></facet-standard-accordion>
              }
              @default {
                <facet-standard-accordion [activeFacet]="activeFacet" [fields]="[facetData.field]" [defaultLabel]="facetData.label" [search]="facetData.search" [icon]="facetData.icon" [options$]="facetData.options$" [selected$]="facetData.selected$" (toggleFacet)="toggleAccordionFacet($event)" (toggleOption)="toggleOption($event)" (clearFacet)="clearFacets([facetData.field])"></facet-standard-accordion>
              }
            }
          </div>
          <div class="hidden lg:block h-full">
            @switch (facetData.type) {
              @case (FacetType.date) {
                <facet-date-wide [activeFacet]="activeFacet" [field]="facetData.field" [defaultLabel]="facetData.label" [icon]="facetData.icon" [options$]="facetData.options$" [selected$]="facetData.selected$" (toggleFacet)="toggleWideFacet($event)" (toggleOption)="toggleOption($event)" (clearFacet)="clearFacets([facetData.field])" (performAction)="doSearch()"></facet-date-wide>
              }
              @case (FacetType.duration) {
                <facet-range-wide [activeFacet]="activeFacet" [fields]="facetData.fields" [defaultLabel]="facetData.label" [icon]="facetData.icon" [facetType]="FacetType.duration" [options$]="facetData.options$" [selected$]="facetData.selected$" (toggleFacet)="toggleWideFacet($event)" (updateRange)="updateRangeOption($event)" (clearFacet)="clearFacets($event)"></facet-range-wide>
              }
              @case (FacetType.price) {
                <facet-range-wide [activeFacet]="activeFacet" [fields]="facetData.fields" [defaultLabel]="facetData.label" [icon]="facetData.icon" [facetType]="FacetType.price" [options$]="facetData.options$" [selected$]="facetData.selected$" (toggleFacet)="toggleWideFacet($event)" (updateRange)="updateRangeOption($event)" (clearFacet)="clearFacets($event)"></facet-range-wide>
              }
              @case (FacetType.destination) {
                <facet-standard-wide [activeFacet]="activeFacet" [fields]="facetData.fields" [defaultLabel]="facetData.label" [search]="facetData.search" [icon]="facetData.icon" [options$]="facetData.options$" [selected$]="facetData.selected$" (toggleFacet)="toggleWideFacet($event)" (toggleOption)="toggleOption($event)" (clearFacet)="clearFacets(facetData.fields)" (performAction)="doSearch()"></facet-standard-wide>
              }
              @default {
                <facet-standard-wide [activeFacet]="activeFacet" [fields]="[facetData.field]" [defaultLabel]="facetData.label" [search]="facetData.search" [icon]="facetData.icon" [options$]="facetData.options$" [selected$]="facetData.selected$" (toggleFacet)="toggleWideFacet($event)" (toggleOption)="toggleOption($event)" (clearFacet)="clearFacets([facetData.field])" (performAction)="doSearch()"></facet-standard-wide>
              }
            }
          </div>
        </li>
      }
      @if (!showMore) {
        <li class="max-w-[300px] lg:max-w-none">
          <button (click)="shouldShowMore()" class="relative z-10 w-full text-sm font-bold rounded-md border-2 border-transparent hover:border-tertiary-100 transition-colors duration-200 group">
            <span class="w-full h-[46px] flex justify-start items-center px-1.5 xl:px-2.5 bg-white rounded shadow-inset-md group-hover:shadow-none">
              <span class="flex justify-center items-center w-[30px] lg:w-[20px] xl:w-[30px] h-[30px] lg:h-[20px] xl:h-[30px] mr-2 p-1.5 rounded-[3px] bg-primary-300 group-hover:bg-tertiary-100 transition-colors duration-200">
                <icon-filter fill="white"></icon-filter>
              </span>
              <span>View All Filters</span>
              <span class="flex-grow flex justify-end group-hover:text-tertiary-100 transition-colors duration-200">
                <i class="relative inline-block -top-px ml-2">
                  <icon-plus [width]="18" fill="black"></icon-plus>
                </i>
              </span>
            </span>
          </button>
        </li>
      }
      <li class="max-w-[300px] lg:max-w-none">
        <button (click)="doSearch()" class="btn relative z-10 w-full h-full flex justify-center items-center">
          <i class="relative inline-block -top-px mr-1 xl:mr-2.5">
            <icon-search fill="white"></icon-search>
          </i>
          <span class="text-xs xl:text-sm">search</span>
        </button>
      </li>
    </ul>
  </div>
</div>
import {
  Component,
  ViewChild,
  Renderer2,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import {CloseIcon} from '@app/icons';
import {Microsite} from '@app/models';
import {ImgixPipe} from '@app/pipes/imgix.pipe';
import {
  HubspotFormService,
  SiteConfigService,
  ESignupDialogService,
} from '@app/services';

@Component({
  selector: 'app-e-signup',
  imports: [ImgixPipe, CloseIcon],
  styleUrl: './e-signup.component.css',
  templateUrl: './e-signup.component.html',
})
export class ESignupComponent implements AfterViewInit {
  site: Microsite;
  submitted = false;
  hsTargetId = 'hsEmail';

  @ViewChild('formDismiss') formDismiss!: ElementRef;

  constructor(
    private renderer: Renderer2,
    private siteConfigService: SiteConfigService,
    private eSignupDialogService: ESignupDialogService,
    private hubspotFormService: HubspotFormService
  ) {
    this.site = this.siteConfigService.getConfig();
  }

  ngAfterViewInit(): void {
    this.hubspotFormService.addForm(
      this.hsTargetId,
      this.site.hubspotFormIDs.eSignupModal,
      {event: 'hs_insider_deals_submit'},
      {onFormSubmitted: this.onFormSubmitted.bind(this)}
    );
  }

  onFormSubmitted() {
    this.submitted = true;
    this.renderer.setProperty(
      this.formDismiss.nativeElement,
      'innerText',
      'Close'
    );
  }

  closeModal(event: Event) {
    event.stopPropagation(); // stop from bubbling up to bg click listener
    this.eSignupDialogService.closeModal(this.submitted);
  }
}

import {
  Component,
  ViewChild,
  Renderer2,
  ElementRef,
  AfterViewInit,
  Inject,
  OnDestroy,
} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {CloseIcon, VolumeOffIcon, VolumeOnIcon} from '@app/icons';
import {Microsite} from '@app/models';
import {
  HubspotFormService,
  SiteConfigService,
  ESignupDialogService,
} from '@app/services';
import {Subject, takeUntil} from 'rxjs';

@Component({
  selector: 'app-wcs-e-signup',
  imports: [CloseIcon, VolumeOnIcon, VolumeOffIcon],
  styleUrl: './wcs-e-signup.component.css',
  templateUrl: './wcs-e-signup.component.html',
})
export class WCSESignupComponent implements AfterViewInit, OnDestroy {
  site: Microsite;
  submitted = false;
  hsTargetId = 'hsEmail';

  // wcs video
  muted = true;
  vidSrc: string | null = null;

  @ViewChild('formDismiss') formDismiss!: ElementRef;
  @ViewChild('video') video!: ElementRef<HTMLVideoElement>;

  private destroy$ = new Subject<void>();

  constructor(
    private renderer: Renderer2,
    private siteConfigService: SiteConfigService,
    private hubspotFormService: HubspotFormService,
    private eSignupDialogService: ESignupDialogService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.site = this.siteConfigService.getConfig();
  }

  ngAfterViewInit(): void {
    this.hubspotFormService.addForm(
      this.hsTargetId,
      this.site.hubspotFormIDs.eSignupModal,
      {event: 'hs_insider_deals_submit'},
      {onFormSubmitted: this.onFormSubmitted.bind(this)}
    );

    // WCS preload video on screens > 768
    if (
      this.video &&
      this.document.defaultView?.innerWidth &&
      this.document.defaultView?.innerWidth > 767
    ) {
      this.vidSrc =
        'https://microsites.imgix.net/general/wcs_modal_loop_sign_up_480.mp4';
      this.video.nativeElement.load();

      this.eSignupDialogService
        .getState()
        .pipe(takeUntil(this.destroy$))
        .subscribe(visible => {
          this.muted = true;
          this.video.nativeElement.currentTime = 0;
          if (visible) {
            this.video.nativeElement.load();
            this.video.nativeElement.play();
          } else {
            this.video.nativeElement.pause();
          }
        });
    }
  }

  onFormSubmitted() {
    this.submitted = true;
    this.renderer.setProperty(
      this.formDismiss.nativeElement,
      'innerText',
      'Close'
    );
  }

  toggleMute(event: Event) {
    event.stopPropagation(); // stop from bubbling up to bg click listener
    this.muted = !this.muted;
  }

  closeModal(event: Event) {
    event.stopPropagation(); // stop from bubbling up to bg click listener
    this.eSignupDialogService.closeModal(this.submitted);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}

<div id="form-wrapper" class="theme-{{site.siteID}} relative bg-silver-grad-1 p-1.5 h-full rounded-sm">
  <div class="flex h-full">
    <div class="relative hidden md:block md:w-1/2">
      <video #video [autoplay]="true" [muted]="muted" [loop]="true" playsinline [preload]="'auto'" poster="https://microsites.imgix.net/general/wcs_modal_vid_poster.jpg" [src]="vidSrc" class="w-full h-full object-cover">
      </video>
      <button (click)="toggleMute($event)" class="absolute bottom-3 left-3 w-11 h-11 rounded-full bg-white shadow-xl" aria-label="Mute/unmute">
        <i class="absolute top-1/2 left-[9px] -translate-y-1/2">
          @if (muted) {
            <icon-volume-off [width]="24"></icon-volume-off>
          } @else {
            <icon-volume-on [width]="24"></icon-volume-on>
          }
        </i>
      </button>
    </div>
    <div class="bg w-full md:w-1/2 pb-20 overflow-y-auto bg-primary-300">
      <div class="flex justify-end">
        <button (click)="closeModal($event)" class="mr-5 my-5 h-8 w-8 text-3xl border rounded-sm border-gray-100" aria-label="Close modal">
          <icon-close [width]="15" class="flex justify-center items-center text-gray-100"></icon-close>
        </button>
      </div>
      <div class="flex justify-center">
        <div class="w-full max-w-[360px] px-9">
          <div class="mb-16">
            <div class="mb-8">
              <div class="text-white text-center text-[64px] leading-[75px] font-theme-display uppercase">enjoy</div>
              <div class="text-white text-center text-3xl font-theme-display uppercase">insider deals</div>
            </div>
            <div class="mb-5 text-tertiary-200 text-center text-lg font-bold uppercase">get exclusive access</div>
            <div class="text-white text-center text-lg">Sign up today to stay up to date on all of our deals.</div>
          </div>
          <div class="h-40">
            <div [id]="hsTargetId" class="w-full"></div>
            <button #formDismiss (click)="closeModal($event)" class="block mx-auto bg-primary-300 border-2 border-rounded border-tertiary-200 rounded px-4 py-2 text-sm font-bold text-tertiary-200 w-44 uppercase">No Thanks</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
import {Routes} from '@angular/router';
import {ContainedLayoutComponent} from './contained-layout/contained-layout.component';
import {HomeComponent} from './home/home.component';
import {RedirectHandler} from './redirects/handler';
import {cruiseLinesGuard} from './cruise-lines/cruise-lines.guard';
import {shipPagesGuard} from './ship-pages/ship-pages.guard';
import {destinationsGuard} from './destinations/destinations.guard';

export const routes: Routes = [
  {
    path: '',
    component: ContainedLayoutComponent,
    children: [
      {path: '', component: HomeComponent},
      {
        path: 'advisor-profiles',
        loadChildren: () =>
          import('./advisors/advisors.routes').then(m => m.routes),
      },
      {
        path: 'contact-us',
        loadChildren: () =>
          import('./contact-us/contact-us.routes').then(m => m.routes),
      },
      {
        path: 'destinations',
        loadChildren: () =>
          import('./destinations/destinations.routes').then(m => m.routes),
        canMatch: [destinationsGuard],
      },
      {
        path: 'page-not-found',
        loadChildren: () =>
          import('./page-not-found/page-not-found.routes').then(m => m.routes),
      },
      {
        path: 'privacy-policy',
        loadChildren: () =>
          import('./privacy/privacy.routes').then(m => m.routes),
      },
      {
        path: 'terms-and-conditions',
        loadChildren: () => import('./terms/terms.routes').then(m => m.routes),
      },
      {
        path: 'about-cruiseline',
        loadChildren: () =>
          import('./about-cruiseline/about-cruiseline.routes').then(
            m => m.routes
          ),
      },
      {
        path: 'promotions',
        loadChildren: () =>
          import('./promotions/promotion.routes').then(m => m.routes),
      },
      {
        path: 'content',
        loadChildren: () =>
          import('./content-page/content-page.routes').then(m => m.routes),
      },
      {
        path: 'ships',
        loadChildren: () =>
          import('./ship-pages/ship-pages.routes').then(m => m.routes),
        canMatch: [shipPagesGuard],
      },
      {
        path: 'frequently-asked-questions',
        loadChildren: () =>
          import('./faq-page/faq-page.routes').then(m => m.routes),
      },
      {
        path: 'itinerary',
        loadChildren: () =>
          import('./itinerary/itinerary.routes').then(m => m.routes),
      },
      {
        path: 'why-choose-us',
        loadChildren: () =>
          import('./why-us/why-us.routes').then(m => m.routes),
      },
      {
        path: 'expeditions',
        loadChildren: () =>
          import('./expeditions/expeditions.routes').then(m => m.routes),
      },
      {
        path: 'cruise-lines',
        loadChildren: () =>
          import('./cruise-lines/cruise-lines.routes').then(m => m.routes),
        canMatch: [cruiseLinesGuard],
      },
      {
        path: 'why-choose-an-alaska-cruise-tour',
        loadChildren: () =>
          import('./why-cruise-tour/why-cruise-tour.routes').then(
            m => m.routes
          ),
      },
      {
        path: 'request-quote',
        loadChildren: () =>
          import('./request-a-quote/request-a-quote.routes').then(
            m => m.routes
          ),
      },
      {
        path: 'search',
        loadChildren: () =>
          import('./search/search.routes').then(m => m.routes),
      },
    ],
  },
  {path: '**', canActivate: [RedirectHandler], children: []},
];

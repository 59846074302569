import {DatePipe} from '@angular/common';
import {Component, AfterViewInit} from '@angular/core';
import {RouterLink} from '@angular/router';
import {Microsite, Site} from '@app/models';
import {ImgixPipe} from '@app/pipes';
import {SiteConfigService, HubspotFormService} from '@app/services';

@Component({
  selector: 'footer',
  imports: [RouterLink, DatePipe, ImgixPipe],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements AfterViewInit {
  readonly Site = Site;
  site: Microsite;
  now: Date = new Date();
  formId = '';

  hsTargetId = 'hsFooterEmail';

  constructor(
    private siteConfigService: SiteConfigService,
    private hubspotFormService: HubspotFormService
  ) {
    this.site = this.siteConfigService.getConfig();
    this.formId = this.siteConfigService.getConfig().hubspotFormIDs.footer;
  }

  // HUBSPOT FORMS
  // Oceania: https://app.hubspot.com/forms/4256703/editor/5a8a04ea-4b0f-4ce0-acd1-27eccc740e1a/edit/form
  // Regent: https://app.hubspot.com/forms/4256703/editor/10ca4016-0f9d-42d1-a8b4-1efd7541eeda/edit/form
  // SilverSea:  https://app.hubspot.com/forms/4256703/editor/e9149cef-3a1f-4f9f-b19e-b9672fa2939e/edit/form
  // Windstar: https://app.hubspot.com/forms/4256703/editor/de16c8de-2b11-4bf4-8979-bd8956c5721d/edit/form

  ngAfterViewInit(): void {
    this.hubspotFormService.addForm(this.hsTargetId, this.formId, {
      event: 'hs_insider_deals_submit',
    });
  }
}

import {inject} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RedirectCommand,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import {isJsonArray, isJsonObject} from '@app/models';
import {ApiService, SiteConfigService} from '@app/services';
import {firstValueFrom} from 'rxjs';

export async function RedirectHandler(
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) {
  const api = inject(ApiService);
  const siteid = inject(SiteConfigService).getConfig().siteID;
  const router = inject(Router);

  const redirects = await firstValueFrom(api.get(`/redirects?site=${siteid}`));
  if (isJsonArray(redirects)) {
    for (const redirect of redirects) {
      if (
        isJsonObject(redirect) &&
        state.url.startsWith(redirect['Path']?.toString() ?? '!!')
      ) {
        return new RedirectCommand(
          router.parseUrl(redirect['Destination']?.toString() ?? '')
        );
      }
    }
  }

  return new RedirectCommand(router.parseUrl('/page-not-found'));
}
